<template>
  <client-only>
    <div class="c_form_input">
      <FormLabel :input-id="formattedInputId" :label-text="labelText" :required="required" v-if="labelText" />
      <textarea
        v-model="inputValue"
        ref="input"
        v-bind="inputAttributes"
        @keyup="valueChanged"
        v-if="inputType === 'textarea'"
      ></textarea>
      <div v-else-if="inputType === 'iconCheckboxes' && inputOptions && inputValue">
        <mq-layout mq="sm">
          <div v-for="option in inputOptions" :key="option.value">
            <FormInputCheckbox
              :aria-label="`${labelText} - ${option.label} - ${
                (inputValue ? inputValue.includes(option.value) : false) ? 'selected' : 'not selected'
              } `"
              :icon-url="option.icon || null"
              :input-checked="inputValue.includes(option.value)"
              input-mode="checkbox"
              :label-text="option.label"
              @onCheckboxClick="toggleValue(option.value)"
            />
          </div>
        </mq-layout>
        <mq-layout mq="md+">
          <div class="c_grid c_grid--2-3">
            <FormInputCheckbox
              :aria-label="`${labelText} - ${option.label} - ${
                (inputValue ? inputValue.includes(option.value) : false) ? 'selected' : 'not selected'
              } `"
              class="c_box--shadow--center"
              :class="{ 'c_box--yellow': inputValue.includes(option.value) }"
              :icon-url="option.icon || null"
              input-mode="checkbox"
              :label-text="option.label"
              @onCheckboxClick="toggleValue(option.value)"
              v-for="option in inputOptions"
              :key="option.value"
            />
          </div>
          <!--        <div class="c_form_input__icon_checkbox__header">-->
          <!--          <IconSVG stroke-color-var="red" stroke-width="4.5px" handle="arrow_up" width="17" height="17" />-->
          <!--          <p>Add other products</p>-->
          <!--        </div>-->
          <!--        <div class="c_form_input__icon_checkbox__unchecked_options c_box&#45;&#45;gray_235 c_grid c_grid&#45;&#45;3-4">-->
          <!--          <FormInputCheckbox-->
          <!--            class="c_box&#45;&#45;white c_box&#45;&#45;shadow&#45;&#45;center"-->
          <!--            :icon-url="option.icon || null"-->
          <!--            :label-text="option.label"-->
          <!--            @onClick="addValue(option.value)"-->
          <!--            v-for="option in inputOptions"-->
          <!--            :key="option.value"-->
          <!--            v-if="!inputValue.includes(option.value)"-->
          <!--          />-->
          <!--        </div>-->
        </mq-layout>
        <div class="c_text--hide--collapse">
          <input
            type="hidden"
            :id="'input-' + inputName"
            :name="inputName"
            :value="inputValue.join(`;`)"
            @keyup="valueChanged"
          />
        </div>
      </div>
      <div v-else-if="inputType === 'checkboxes' && inputOptions">
        <FormInputCheckbox
          :aria-label="`${labelText} - ${option.label} - ${
            (inputValue ? inputValue.includes(option.value) : false) ? 'selected' : 'not selected'
          } `"
          :input-checked="inputValue ? inputValue.includes(option.value) : false"
          input-mode="checkbox"
          :label-text="option.label"
          @onCheckboxClick="toggleValue(option.value)"
          v-for="option in inputOptions"
          :key="option.value"
        />
        <div class="c_text--hide--collapse">
          <input
            type="hidden"
            :name="'input-' + inputName"
            :id="inputName"
            :value="inputValue ? inputValue.join(`;`) : ''"
            @keyup="valueChanged"
          />
        </div>
      </div>
      <div v-else-if="inputType === 'checkbox'">
        <FormInputCheckbox
          :input-checked="inputValue"
          input-mode="checkbox"
          :label-text="labelText"
          @onCheckboxClick="
            () => {
              updateValue(!inputValue);
              valueChanged();
            }
          "
        />
      </div>
      <div v-else-if="inputType === 'radio' && inputOptions">
        <FormInputCheckbox
          :aria-label="`${labelText} - ${option.label} - ${
            (inputValue ? inputValue.includes(option.value) : false) ? 'selected' : 'not selected'
          } `"
          :input-checked="option.value === inputValue"
          input-mode="radio"
          :label-text="option.label"
          @onCheckboxClick="updateValue(option.value)"
          v-for="option in inputOptions"
          :key="option.value"
        />
        <div class="c_text--hide--collapse">
          <input type="hidden" :id="'input-' + inputName" :name="inputName" :value="inputValue" @keyup="valueChanged" />
        </div>
      </div>
      <div v-else-if="inputType === 'select' && inputOptions">
        <select v-bind="inputAttributes" v-model="inputValue" @blur="validateField" @change="valueChanged">
          <option v-if="inputPlaceholder" class="placeholder" selected disabled value="">{{ inputPlaceholder }}</option>
          <option
            v-if="inputOptions"
            :selected="option.value === startingValue"
            :value="option.value"
            v-for="(option, index) in inputOptions"
            :key="index"
          >
            {{ option.label }}
          </option>
        </select>
      </div>
      <div v-else-if="inputType === 'multiselect' && inputOptions" class="multi-select">
        <input
          :placeholder="inputPlaceholder"
          :value="inputValue && inputValue.length > 0 ? inputValue.join(', ') : undefined"
          @click="openMultiSelect"
        />
        <!--Locator dropdown-->
        <IconSVG class="multi-select__icon" handle="dropdown" :alt="$t('dropdown')" @click="openMultiSelect"></IconSVG>
        <div
          v-if="inputOptions"
          v-show="isMultiSelectOpen"
          class="multi-select__options"
          @mouseleave="closeMultiSelect"
        >
          <FormInputCheckbox
            v-for="(option, index) in inputOptions"
            :key="index"
            :input-checked="inputValue ? inputValue.includes(option.value) : false"
            input-mode="checkbox"
            :label-text="option.label"
            @onCheckboxClick="
              () => {
                toggleValue(option.value);
                valueChanged();
              }
            "
          />
          <div v-if="isMobileDisplay" class="multi-select__close" @click="closeMultiSelect">
            <IconSVG handle="close" :alt="$t('close')"></IconSVG>
          </div>
        </div>
      </div>
      <cleave
        ref="input"
        :options="cleaveOptions"
        v-model="inputValue"
        v-bind="inputAttributes"
        @blur="validateField"
        @input="valueChanged"
        v-else-if="formatType !== undefined || formatOptions !== undefined"
      />
      <input
        ref="input"
        v-model="inputValue"
        v-bind="inputAttributes"
        @blur="validateField"
        @keyup="valueChanged"
        v-else
      />

      <div class="c_form_input__error" v-if="!isValid">{{ error }}</div>
    </div>
  </client-only>
</template>

<script>
import * as svgs from 'JS/automated/svg.js';
import FormLabel from 'Components/form/FormLabel.vue';
import FormInputCheckbox from 'Components/form/FormInputCheckbox.vue';
import IconSVG from 'Components/icon_svg/IconSVG.vue';

export default {
  components: {
    cleave: () => import('vue-cleave-component'),
    FormLabel,
    FormInputCheckbox,
    IconSVG,
  },
  data() {
    return {
      error: false,
      cleaveOptions: {},
      isValid: true,
      inputValue: null,
      isMultiSelectOpen: false,
    };
  },
  props: {
    ariaLabel: String,
    errorMessage: String,
    formatOptions: Object,
    formatType: String,
    inputAttr: Object,
    inputClasses: {
      type: Array,
      default() {
        return [];
      },
    },
    inputId: String,
    inputName: { type: String, required: true },
    inputOptions: Array,
    inputPlaceholder: String,
    inputType: { type: String, default: 'text' },
    startingValue: { type: [Array, String, Boolean], default: null },
    labelText: String,
    required: { type: Boolean, default: false },
    validationType: String,
    options: Object,
    value: { type: [Array, String, Boolean], default: null },
    isMobileDisplay: Boolean,
  },
  computed: {
    formattedInputId() {
      return this.inputId || `input-${this.inputName}`;
    },
    inputAttributes() {
      const attrs = { style: {} };

      attrs.id = this.formattedInputId;
      attrs.class = this.inputClasses || [];
      attrs.name = this.inputName;
      attrs['aria-label'] = this.ariaLabel || this.labelText || this.name;

      if (this.inputPlaceholder) {
        attrs.placeholder = this.inputPlaceholder;
      }
      if (!this.isValid) {
        attrs.class.push('c_form_input--invalid');
      } else {
        attrs.class = attrs.class.filter((e) => e !== 'c_form_input--invalid');
      }
      if (this.required) {
        attrs.required = this.required;
      }

      switch (this.inputType) {
        case 'select':
          attrs.style['--select-icon'] = svgs.background('arrow_down', {
            'stroke="#000"': 'stroke="rgb(112, 112, 112)"',
          });
          break;
        default:
          attrs.type = this.inputType;
      }

      if (this.inputAttr) {
        Object.assign(attrs, this.inputAttr);
      }

      return attrs;
    },
    passesRequired() {
      let passes = false;
      if (this.inputValue) {
        switch (typeof this.inputValue) {
          case 'object':
            passes = this.inputValue.length > 0;
            break;
          case 'string':
            passes = this.inputValue !== '';
            break;
        }
      }
      return passes;
    },
    validationEnabled() {
      return this.validationType;
    },
  },
  methods: {
    addValue(value) {
      if (typeof this.inputValue === 'object') {
        if (!this.inputValue?.includes(value)) {
          this.inputValue.push(value);
          this.validateField();
        }
      }
    },
    removeValue(value) {
      if (typeof this.inputValue === 'object') {
        this.inputValue = this.inputValue.filter((item) => item !== value);
        this.validateField();
      }
    },
    toggleValue(value) {
      if (typeof this.inputValue === 'object') {
        if (this.inputValue?.includes(value)) {
          this.removeValue(value);
        } else {
          this.addValue(value);
        }
      }
    },
    updateValue(value) {
      this.inputValue = value;
      this.validateField();
    },
    setErrorForFormId(message = null) {
      this.error = this.errorMessage || message;
      this.$emit('formSetError', this.inputName, this.error);
    },
    removeErrorForFormId() {
      this.$emit('formRemoveError', this.inputName);
    },
    validateField() {
      this.isValid = true;

      if (this.validationEnabled) {
        let validation = { valid: false, message: '' };

        switch (this.validationType) {
          case 'email':
            validation = this.validEmail(this.inputValue);
            break;
          case 'edu':
            validation = this.validEdu(this.inputValue);
            break;
          case 'tel':
            validation = this.validTel(this.inputValue);
            break;
          case 'text':
            validation = this.validText(this.inputValue);
            break;
          case 'zip':
            validation = this.validZip(this.inputValue);
            break;
        }

        this.isValid = validation.valid;

        if (!validation.valid || (this.inputValue === '' && this.required)) {
          this.setErrorForFormId(validation.message);
        } else {
          this.error = false;
          this.removeErrorForFormId();
        }
      } else if (this.required) {
        if (this.passesRequired) {
          this.error = false;
          this.removeErrorForFormId();
        } else {
          this.isValid = false;
          this.setErrorForFormId(this.labelText ? `${this.labelText} ${this.$t('is required.')}` : null);
        }
      }
    },
    validEmail(value) {
      const re =
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return { valid: re.test(value), message: this.$t('Please enter a valid email address.') };
    },
    // validSelect: function(value) {
    //   return { valid: value != '', message: 'Please choose a valid option.' };
    // },
    validEdu(value) {
      const re = /^[a-z0-9._%+-]+@[a-z0-9.-]+\.edu$/i;
      return { valid: re.test(value), message: this.$t('Please enter a valid .edu email address.') };
    },
    validTel(value) {
      const re = /^\d{10}$/;
      return { valid: re.test(value), message: this.$t('Please enter a valid phone number.') };
    },
    validText(value) {
      return { valid: value !== '', message: this.$t('Please enter text.') };
    },
    validZip(value) {
      let re;
      switch (this.options?.country) {
        case 'Canada':
          re = /^[A-Za-z]{1}\d{1}[A-Za-z]{1}\d{1}[A-Za-z]{1}\d{1}$/;
          break;

        default:
          re = /^\d{5}$/;
          break;
      }
      return { valid: re.test(value), message: this.$t('Please enter a valid ZIP code.') };
    },
    valueChanged() {
      this.$emit('onValueChange', this.inputName, this.inputValue);
    },
    setCleaveOptions() {
      this.cleaveOptions = {};

      switch (this.formatType) {
        /* case 'email':
          this.cleaveOptions = {
            delimiter: '@',
            numericOnly: false,
          };
          break; */
        case 'tel':
          this.cleaveOptions = {
            delimiter: '-',
            blocks: [3, 3, 4],
            numericOnly: true,
          };
          break;
        case 'zip':
          switch (this.options?.country) {
            case 'Canada':
              this.cleaveOptions = {
                delimiter: ' ',
                blocks: [3, 3],
                numericOnly: false,
              };
              break;

            default:
              this.cleaveOptions = {
                blocks: [5],
                numericOnly: true,
              };
              break;
          }

          break;
      }

      if (this.formatOptions) {
        Object.assign(this.cleaveOptions, this.formatOptions);
      }
    },
    openMultiSelect() {
      this.isMultiSelectOpen = true;
    },
    closeMultiSelect() {
      this.isMultiSelectOpen = false;
    },
  },
  mounted() {
    // console.log('CHECKING STARTING VALUE', this.startingValue, this.inputType, this.labelText);
    if (this.startingValue !== null && this.startingValue !== undefined) {
      this.inputValue = this.startingValue;
    } else if (['iconCheckboxes', 'checkboxes', 'multiselect'].includes(this.inputType)) {
      this.inputValue = [];
    }

    if (this.formatType) {
      this.setCleaveOptions();
    }
  },
  watch: {
    options() {
      this.setCleaveOptions();
    },
    value() {
      this.inputValue = this.value;
    },
  },
};
</script>

<style lang="scss" scoped>
.multi-select {
  &__icon {
    position: absolute;
    top: 18px;
    right: 16px;
    width: 16px;
  }

  &__close {
    position: absolute;
    cursor: pointer;
    top: 16px;
    right: 16px;
    width: 16px;
  }
}
</style>
